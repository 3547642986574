import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getDatabase, connectDatabaseEmulator } from 'firebase/database'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions'
import { getAnalytics, isSupported as isAnalyticsSupported } from 'firebase/analytics'

export default defineNuxtPlugin(async nuxtApp => {
  const config = useRuntimeConfig()
  const env = config.public
  // @ts-expect-error
  const app = initializeApp({
    apiKey: env.FIREBASE_API_KEY,
    authDomain: env.FIREBASE_AUTH_DOMAIN,
    projectId: env.FIREBASE_PROJECT_ID,
    storageBucket: env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
    appId: env.FIREBASE_APP_ID,
    measurementId: env.FIREBASE_MEASUREMENT_ID,
    databaseURL: env.FIREBASE_DATABASE_URL
  })
  const auth = getAuth(app)
  const firestore = getFirestore(app)
  const realtimedb = getDatabase(app)
  const storage = getStorage(app)
  const functions = getFunctions(app, 'europe-west3')

  const analyticsSupported = await isAnalyticsSupported()

  if (analyticsSupported && env.FIREBASE_MEASUREMENT_ID) {
    getAnalytics(app)
  }

  // In local development, use Firebase Local Emulator Suite
  if (import.meta.dev) {
    connectAuthEmulator(auth, 'http://localhost:3011')
    connectFirestoreEmulator(firestore, 'localhost', 3012)
    connectDatabaseEmulator(realtimedb, 'localhost', 3013)
    connectStorageEmulator(storage, 'localhost', 3014)
    connectFunctionsEmulator(functions, 'localhost', 3015)
  }

  if (window) {
    window.firebase = {
      app,
      auth,
      firestore,
      realtimedb,
      storage,
      functions
    }

    window.httpsCallable = httpsCallable<any, any>
  }

  nuxtApp.provide('firebase', {
    app,
    auth,
    firestore,
    realtimedb,
    storage,
    functions
  })
})
