import { default as _91configId_93lFqVvZZQo5Meta } from "/Volumes/Drive/bgd/Documents/live-display/player/pages/[configId].vue?macro=true";
import { default as indexdltGMgHlc6Meta } from "/Volumes/Drive/bgd/Documents/live-display/player/pages/index.vue?macro=true";
import { default as _91pageId_93aRtVvWXLVYMeta } from "/Volumes/Drive/bgd/Documents/live-display/player/pages/playlists/[playlistId]/[pageId].vue?macro=true";
import { default as index9MiIQn79IAMeta } from "/Volumes/Drive/bgd/Documents/live-display/player/pages/playlists/[playlistId]/index.vue?macro=true";
import { default as _91playlistId_93YmDDrlLNOzMeta } from "/Volumes/Drive/bgd/Documents/live-display/player/pages/playlists/[playlistId].vue?macro=true";
import { default as indexGxIQ4xG1bsMeta } from "/Volumes/Drive/bgd/Documents/live-display/player/pages/playlists/index.vue?macro=true";
import { default as playlists1RkbWYps5RMeta } from "/Volumes/Drive/bgd/Documents/live-display/player/pages/playlists.vue?macro=true";
export default [
  {
    name: "configId",
    path: "/:configId()",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/player/pages/[configId].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/player/pages/index.vue")
  },
  {
    name: playlists1RkbWYps5RMeta?.name,
    path: "/playlists",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/player/pages/playlists.vue"),
    children: [
  {
    name: _91playlistId_93YmDDrlLNOzMeta?.name,
    path: ":playlistId()",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/player/pages/playlists/[playlistId].vue"),
    children: [
  {
    name: "playlists-playlistId-pageId",
    path: ":pageId()",
    meta: _91pageId_93aRtVvWXLVYMeta || {},
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/player/pages/playlists/[playlistId]/[pageId].vue")
  },
  {
    name: "playlists-playlistId",
    path: "",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/player/pages/playlists/[playlistId]/index.vue")
  }
]
  },
  {
    name: "playlists",
    path: "",
    meta: indexGxIQ4xG1bsMeta || {},
    redirect: "/",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/player/pages/playlists/index.vue")
  }
]
  }
]